"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimOverwolfRewardResponseToJSON = exports.ClaimOverwolfRewardResponseFromJSONTyped = exports.ClaimOverwolfRewardResponseFromJSON = void 0;
var _1 = require("./");
function ClaimOverwolfRewardResponseFromJSON(json) {
    return ClaimOverwolfRewardResponseFromJSONTyped(json, false);
}
exports.ClaimOverwolfRewardResponseFromJSON = ClaimOverwolfRewardResponseFromJSON;
function ClaimOverwolfRewardResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rewards': (json['rewards'].map(_1.RewardResponseObjectFromJSON)),
    };
}
exports.ClaimOverwolfRewardResponseFromJSONTyped = ClaimOverwolfRewardResponseFromJSONTyped;
function ClaimOverwolfRewardResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rewards': (value.rewards.map(_1.RewardResponseObjectToJSON)),
    };
}
exports.ClaimOverwolfRewardResponseToJSON = ClaimOverwolfRewardResponseToJSON;
