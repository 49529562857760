"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWGameResponseAllOfToJSON = exports.OWGameResponseAllOfFromJSONTyped = exports.OWGameResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function OWGameResponseAllOfFromJSON(json) {
    return OWGameResponseAllOfFromJSONTyped(json, false);
}
exports.OWGameResponseAllOfFromJSON = OWGameResponseAllOfFromJSON;
function OWGameResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasUserStats': !(0, runtime_1.exists)(json, 'hasUserStats') ? undefined : json['hasUserStats'],
    };
}
exports.OWGameResponseAllOfFromJSONTyped = OWGameResponseAllOfFromJSONTyped;
function OWGameResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasUserStats': value.hasUserStats,
    };
}
exports.OWGameResponseAllOfToJSON = OWGameResponseAllOfToJSON;
