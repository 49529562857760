"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGuildQuestsResponseAllOfToJSON = exports.GetGuildQuestsResponseAllOfFromJSONTyped = exports.GetGuildQuestsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetGuildQuestsResponseAllOfFromJSON(json) {
    return GetGuildQuestsResponseAllOfFromJSONTyped(json, false);
}
exports.GetGuildQuestsResponseAllOfFromJSON = GetGuildQuestsResponseAllOfFromJSON;
function GetGuildQuestsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quests': (json['quests'].map(_1.GuildQuestFromJSON)),
    };
}
exports.GetGuildQuestsResponseAllOfFromJSONTyped = GetGuildQuestsResponseAllOfFromJSONTyped;
function GetGuildQuestsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quests': (value.quests.map(_1.GuildQuestToJSON)),
    };
}
exports.GetGuildQuestsResponseAllOfToJSON = GetGuildQuestsResponseAllOfToJSON;
