"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfEventTypesResponseToJSON = exports.GetOverwolfEventTypesResponseFromJSONTyped = exports.GetOverwolfEventTypesResponseFromJSON = void 0;
var _1 = require("./");
function GetOverwolfEventTypesResponseFromJSON(json) {
    return GetOverwolfEventTypesResponseFromJSONTyped(json, false);
}
exports.GetOverwolfEventTypesResponseFromJSON = GetOverwolfEventTypesResponseFromJSON;
function GetOverwolfEventTypesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, _1.PaginationInfoFromJSON)(json['paginationInfo']),
        'eventTypes': (json['eventTypes'].map(_1.OWEventTypeResponseFromJSON)),
    };
}
exports.GetOverwolfEventTypesResponseFromJSONTyped = GetOverwolfEventTypesResponseFromJSONTyped;
function GetOverwolfEventTypesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, _1.PaginationInfoToJSON)(value.paginationInfo),
        'eventTypes': (value.eventTypes.map(_1.OWEventTypeResponseToJSON)),
    };
}
exports.GetOverwolfEventTypesResponseToJSON = GetOverwolfEventTypesResponseToJSON;
