"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopGuildLeaderboardToJSON = exports.TopGuildLeaderboardFromJSONTyped = exports.TopGuildLeaderboardFromJSON = void 0;
var runtime_1 = require("../runtime");
function TopGuildLeaderboardFromJSON(json) {
    return TopGuildLeaderboardFromJSONTyped(json, false);
}
exports.TopGuildLeaderboardFromJSON = TopGuildLeaderboardFromJSON;
function TopGuildLeaderboardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'totalPoints': json['totalPoints'],
        'rank': !(0, runtime_1.exists)(json, 'rank') ? undefined : json['rank'],
    };
}
exports.TopGuildLeaderboardFromJSONTyped = TopGuildLeaderboardFromJSONTyped;
function TopGuildLeaderboardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'icon': value.icon,
        'name': value.name,
        'totalPoints': value.totalPoints,
        'rank': value.rank,
    };
}
exports.TopGuildLeaderboardToJSON = TopGuildLeaderboardToJSON;
