"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountToJSON = exports.OrderDiscountFromJSONTyped = exports.OrderDiscountFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OrderDiscountFromJSON(json) {
    return OrderDiscountFromJSONTyped(json, false);
}
exports.OrderDiscountFromJSON = OrderDiscountFromJSON;
function OrderDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'label': json['label'],
        'internalName': !(0, runtime_1.exists)(json, 'internalName') ? undefined : json['internalName'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : json['businesses'],
        'priority': json['priority'],
        'amount': json['amount'],
        'amountMax': json['amountMax'],
        'isReusableToAmountMax': !(0, runtime_1.exists)(json, 'isReusableToAmountMax') ? undefined : json['isReusableToAmountMax'],
        'customers': json['customers'],
        'subtotalMin': json['subtotalMin'],
        'expiresAt': !(0, runtime_1.exists)(json, 'expiresAt') ? undefined : json['expiresAt'],
        'type': (0, _1.OrderDiscountTypeFromJSON)(json['type']),
        'calculation': (0, _1.OrderDiscountCalculationFromJSON)(json['calculation']),
        'origin': (0, _1.OrderDiscountOriginFromJSON)(json['origin']),
        'isEnabled': json['isEnabled'],
        'isSingleUseUser': json['isSingleUseUser'],
        'isDisplayedGoFrens': json['isDisplayedGoFrens'],
        'isSingleUseSystem': json['isSingleUseSystem'],
        'isStackablePromo': json['isStackablePromo'],
        'isFirstOrder': json['isFirstOrder'],
        'referralTypes': (json['referralTypes'].map(_1.RelationshipTypeFromJSON)),
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'nftOperator': !(0, runtime_1.exists)(json, 'nftOperator') ? undefined : (0, _1.OrderDiscountOperatorFromJSON)(json['nftOperator']),
        'codes': !(0, runtime_1.exists)(json, 'codes') ? undefined : json['codes'],
        'maxRedemptions': !(0, runtime_1.exists)(json, 'maxRedemptions') ? undefined : json['maxRedemptions'],
        'timesRedeemed': !(0, runtime_1.exists)(json, 'timesRedeemed') ? undefined : json['timesRedeemed'],
        'useFrequencyQualifier': !(0, runtime_1.exists)(json, 'useFrequencyQualifier') ? undefined : (0, _1.OrderDiscountFrequencyQualifierFromJSON)(json['useFrequencyQualifier']),
        'useFrequency': !(0, runtime_1.exists)(json, 'useFrequency') ? undefined : json['useFrequency'],
        'menuCategories': !(0, runtime_1.exists)(json, 'menuCategories') ? undefined : json['menuCategories'],
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : json['menuItems'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'isBrandMapValid': !(0, runtime_1.exists)(json, 'isBrandMapValid') ? undefined : json['isBrandMapValid'],
        'isBrandMapAutoApplied': !(0, runtime_1.exists)(json, 'isBrandMapAutoApplied') ? undefined : json['isBrandMapAutoApplied'],
        'handoffOptions': !(0, runtime_1.exists)(json, 'handoffOptions') ? undefined : (json['handoffOptions'].map(_1.HandoffOptionsFromJSON)),
    };
}
exports.OrderDiscountFromJSONTyped = OrderDiscountFromJSONTyped;
function OrderDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'label': value.label,
        'internalName': value.internalName,
        'description': value.description,
        'businesses': value.businesses,
        'priority': value.priority,
        'amount': value.amount,
        'amountMax': value.amountMax,
        'isReusableToAmountMax': value.isReusableToAmountMax,
        'customers': value.customers,
        'subtotalMin': value.subtotalMin,
        'expiresAt': value.expiresAt,
        'type': (0, _1.OrderDiscountTypeToJSON)(value.type),
        'calculation': (0, _1.OrderDiscountCalculationToJSON)(value.calculation),
        'origin': (0, _1.OrderDiscountOriginToJSON)(value.origin),
        'isEnabled': value.isEnabled,
        'isSingleUseUser': value.isSingleUseUser,
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'isSingleUseSystem': value.isSingleUseSystem,
        'isStackablePromo': value.isStackablePromo,
        'isFirstOrder': value.isFirstOrder,
        'referralTypes': (value.referralTypes.map(_1.RelationshipTypeToJSON)),
        'nftGroupings': value.nftGroupings,
        'nftOperator': (0, _1.OrderDiscountOperatorToJSON)(value.nftOperator),
        'codes': value.codes,
        'maxRedemptions': value.maxRedemptions,
        'timesRedeemed': value.timesRedeemed,
        'useFrequencyQualifier': (0, _1.OrderDiscountFrequencyQualifierToJSON)(value.useFrequencyQualifier),
        'useFrequency': value.useFrequency,
        'menuCategories': value.menuCategories,
        'menuItems': value.menuItems,
        'image': value.image,
        'isBrandMapValid': value.isBrandMapValid,
        'isBrandMapAutoApplied': value.isBrandMapAutoApplied,
        'handoffOptions': value.handoffOptions === undefined ? undefined : (value.handoffOptions.map(_1.HandoffOptionsToJSON)),
    };
}
exports.OrderDiscountToJSON = OrderDiscountToJSON;
