"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOverwolfGameBodyToJSON = exports.CreateOverwolfGameBodyFromJSONTyped = exports.CreateOverwolfGameBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function CreateOverwolfGameBodyFromJSON(json) {
    return CreateOverwolfGameBodyFromJSONTyped(json, false);
}
exports.CreateOverwolfGameBodyFromJSON = CreateOverwolfGameBodyFromJSON;
function CreateOverwolfGameBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': json['gameId'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'events': json['events'],
        'isEventSupported': json['isEventSupported'],
        'isEventSupportReady': json['isEventSupportReady'],
    };
}
exports.CreateOverwolfGameBodyFromJSONTyped = CreateOverwolfGameBodyFromJSONTyped;
function CreateOverwolfGameBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gameId': value.gameId,
        'name': value.name,
        'description': value.description,
        'events': value.events,
        'isEventSupported': value.isEventSupported,
        'isEventSupportReady': value.isEventSupportReady,
    };
}
exports.CreateOverwolfGameBodyToJSON = CreateOverwolfGameBodyToJSON;
