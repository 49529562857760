"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse20020ToJSON = exports.InlineResponse20020FromJSONTyped = exports.InlineResponse20020FromJSON = void 0;
function InlineResponse20020FromJSON(json) {
    return InlineResponse20020FromJSONTyped(json, false);
}
exports.InlineResponse20020FromJSON = InlineResponse20020FromJSON;
function InlineResponse20020FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nickname': json['nickname'],
    };
}
exports.InlineResponse20020FromJSONTyped = InlineResponse20020FromJSONTyped;
function InlineResponse20020ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nickname': value.nickname,
    };
}
exports.InlineResponse20020ToJSON = InlineResponse20020ToJSON;
