"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWQuestResponseToJSON = exports.OWQuestResponseFromJSONTyped = exports.OWQuestResponseFromJSON = void 0;
function OWQuestResponseFromJSON(json) {
    return OWQuestResponseFromJSONTyped(json, false);
}
exports.OWQuestResponseFromJSON = OWQuestResponseFromJSON;
function OWQuestResponseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWQuestResponseFromJSONTyped = OWQuestResponseFromJSONTyped;
function OWQuestResponseToJSON(value) {
    return value;
}
exports.OWQuestResponseToJSON = OWQuestResponseToJSON;
