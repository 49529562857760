"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQuestActiveStatusToJSON = exports.GetQuestActiveStatusFromJSONTyped = exports.GetQuestActiveStatusFromJSON = exports.GetQuestActiveStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetQuestActiveStatus;
(function (GetQuestActiveStatus) {
    GetQuestActiveStatus["ACTIVE"] = "ACTIVE";
    GetQuestActiveStatus["INACTIVE"] = "INACTIVE";
})(GetQuestActiveStatus = exports.GetQuestActiveStatus || (exports.GetQuestActiveStatus = {}));
function GetQuestActiveStatusFromJSON(json) {
    return GetQuestActiveStatusFromJSONTyped(json, false);
}
exports.GetQuestActiveStatusFromJSON = GetQuestActiveStatusFromJSON;
function GetQuestActiveStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetQuestActiveStatusFromJSONTyped = GetQuestActiveStatusFromJSONTyped;
function GetQuestActiveStatusToJSON(value) {
    return value;
}
exports.GetQuestActiveStatusToJSON = GetQuestActiveStatusToJSON;
