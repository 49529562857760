"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LootboxRewardToJSON = exports.LootboxRewardFromJSONTyped = exports.LootboxRewardFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function LootboxRewardFromJSON(json) {
    return LootboxRewardFromJSONTyped(json, false);
}
exports.LootboxRewardFromJSON = LootboxRewardFromJSON;
function LootboxRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, _1.GetNftRewardResponseFromJSONTyped)(json, ignoreDiscriminator)), { 'levelCompleted': !(0, runtime_1.exists)(json, 'levelCompleted') ? undefined : json['levelCompleted'] });
}
exports.LootboxRewardFromJSONTyped = LootboxRewardFromJSONTyped;
function LootboxRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, _1.GetNftRewardResponseToJSON)(value)), { 'levelCompleted': value.levelCompleted });
}
exports.LootboxRewardToJSON = LootboxRewardToJSON;
