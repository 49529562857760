"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildToJSON = exports.GuildFromJSONTyped = exports.GuildFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GuildFromJSON(json) {
    return GuildFromJSONTyped(json, false);
}
exports.GuildFromJSON = GuildFromJSON;
function GuildFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'games': json['games'],
        'nftGrouping': json['nftGrouping'],
        'totalPoints': json['totalPoints'],
        'monthlyPoints': json['monthlyPoints'],
        'autoMintContract': !(0, runtime_1.exists)(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'autoMintChain': !(0, runtime_1.exists)(json, 'autoMintChain') ? undefined : (0, _1.EVMChainFromJSON)(json['autoMintChain']),
        'hero': !(0, runtime_1.exists)(json, 'hero') ? undefined : json['hero'],
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
        'rank': !(0, runtime_1.exists)(json, 'rank') ? undefined : json['rank'],
        'monthlyRank': !(0, runtime_1.exists)(json, 'monthlyRank') ? undefined : json['monthlyRank'],
        'memberCount': !(0, runtime_1.exists)(json, 'memberCount') ? undefined : json['memberCount'],
    };
}
exports.GuildFromJSONTyped = GuildFromJSONTyped;
function GuildToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'games': value.games,
        'nftGrouping': value.nftGrouping,
        'totalPoints': value.totalPoints,
        'monthlyPoints': value.monthlyPoints,
        'autoMintContract': value.autoMintContract,
        'autoMintChain': (0, _1.EVMChainToJSON)(value.autoMintChain),
        'hero': value.hero,
        'icon': value.icon,
        'rank': value.rank,
        'monthlyRank': value.monthlyRank,
        'memberCount': value.memberCount,
    };
}
exports.GuildToJSON = GuildToJSON;
