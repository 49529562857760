"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestResetToJSON = exports.QuestResetFromJSONTyped = exports.QuestResetFromJSON = exports.QuestReset = void 0;
/**
 * When the quest resets. The user stats for this quest will be reset.
 * @export
 * @enum {string}
 */
var QuestReset;
(function (QuestReset) {
    QuestReset["MONTHLY"] = "MONTHLY";
    QuestReset["DAILY"] = "DAILY";
    QuestReset["WEEKLY"] = "WEEKLY";
    QuestReset["NONE"] = "NONE";
})(QuestReset = exports.QuestReset || (exports.QuestReset = {}));
function QuestResetFromJSON(json) {
    return QuestResetFromJSONTyped(json, false);
}
exports.QuestResetFromJSON = QuestResetFromJSON;
function QuestResetFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.QuestResetFromJSONTyped = QuestResetFromJSONTyped;
function QuestResetToJSON(value) {
    return value;
}
exports.QuestResetToJSON = QuestResetToJSON;
