"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWRewardMaxTypeToJSON = exports.OWRewardMaxTypeFromJSONTyped = exports.OWRewardMaxTypeFromJSON = exports.OWRewardMaxType = void 0;
/**
 * The time period at which we are setting the max reward for (weekly, monthly)
 * @export
 * @enum {string}
 */
var OWRewardMaxType;
(function (OWRewardMaxType) {
    OWRewardMaxType["WEEKLY"] = "WEEKLY";
})(OWRewardMaxType = exports.OWRewardMaxType || (exports.OWRewardMaxType = {}));
function OWRewardMaxTypeFromJSON(json) {
    return OWRewardMaxTypeFromJSONTyped(json, false);
}
exports.OWRewardMaxTypeFromJSON = OWRewardMaxTypeFromJSON;
function OWRewardMaxTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWRewardMaxTypeFromJSONTyped = OWRewardMaxTypeFromJSONTyped;
function OWRewardMaxTypeToJSON(value) {
    return value;
}
exports.OWRewardMaxTypeToJSON = OWRewardMaxTypeToJSON;
