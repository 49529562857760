"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftGroupingToJSON = exports.NftGroupingFromJSONTyped = exports.NftGroupingFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftGroupingFromJSON(json) {
    return NftGroupingFromJSONTyped(json, false);
}
exports.NftGroupingFromJSON = NftGroupingFromJSON;
function NftGroupingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'sortPriority': !(0, runtime_1.exists)(json, 'sortPriority') ? undefined : json['sortPriority'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'discordUrl': !(0, runtime_1.exists)(json, 'discordUrl') ? undefined : json['discordUrl'],
        'twitterUrl': !(0, runtime_1.exists)(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'websiteUrl': !(0, runtime_1.exists)(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'hero': !(0, runtime_1.exists)(json, 'hero') ? undefined : (0, _1.AssetFromJSON)(json['hero']),
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : (0, _1.AssetFromJSON)(json['icon']),
        'disabled': !(0, runtime_1.exists)(json, 'disabled') ? undefined : json['disabled'],
        'isGuildGrouping': !(0, runtime_1.exists)(json, 'isGuildGrouping') ? undefined : json['isGuildGrouping'],
        'numOfMembers': !(0, runtime_1.exists)(json, 'numOfMembers') ? undefined : json['numOfMembers'],
        'rank': !(0, runtime_1.exists)(json, 'rank') ? undefined : json['rank'],
        'categories': !(0, runtime_1.exists)(json, 'categories') ? undefined : json['categories'],
        'totalPoints': !(0, runtime_1.exists)(json, 'totalPoints') ? undefined : json['totalPoints'],
        'chains': !(0, runtime_1.exists)(json, 'chains') ? undefined : (json['chains'].map(_1.EVMChainFromJSON)),
    };
}
exports.NftGroupingFromJSONTyped = NftGroupingFromJSONTyped;
function NftGroupingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'sortPriority': value.sortPriority,
        'description': value.description,
        'discordUrl': value.discordUrl,
        'twitterUrl': value.twitterUrl,
        'websiteUrl': value.websiteUrl,
        'hero': (0, _1.AssetToJSON)(value.hero),
        'icon': (0, _1.AssetToJSON)(value.icon),
        'disabled': value.disabled,
        'isGuildGrouping': value.isGuildGrouping,
        'numOfMembers': value.numOfMembers,
        'rank': value.rank,
        'categories': value.categories,
        'totalPoints': value.totalPoints,
        'chains': value.chains === undefined ? undefined : (value.chains.map(_1.EVMChainToJSON)),
    };
}
exports.NftGroupingToJSON = NftGroupingToJSON;
