"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfQuestsResponseAllOfToJSON = exports.GetOverwolfQuestsResponseAllOfFromJSONTyped = exports.GetOverwolfQuestsResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetOverwolfQuestsResponseAllOfFromJSON(json) {
    return GetOverwolfQuestsResponseAllOfFromJSONTyped(json, false);
}
exports.GetOverwolfQuestsResponseAllOfFromJSON = GetOverwolfQuestsResponseAllOfFromJSON;
function GetOverwolfQuestsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quests': (json['quests'].map(_1.OWQuestResponseFromJSON)),
        'allEventTypes': (json['allEventTypes'].map(_1.OWEventTypeResponseFromJSON)),
        'allGames': !(0, runtime_1.exists)(json, 'allGames') ? undefined : (json['allGames'].map(_1.OWGameResponseFromJSON)),
    };
}
exports.GetOverwolfQuestsResponseAllOfFromJSONTyped = GetOverwolfQuestsResponseAllOfFromJSONTyped;
function GetOverwolfQuestsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quests': (value.quests.map(_1.OWQuestResponseToJSON)),
        'allEventTypes': (value.allEventTypes.map(_1.OWEventTypeResponseToJSON)),
        'allGames': value.allGames === undefined ? undefined : (value.allGames.map(_1.OWGameResponseToJSON)),
    };
}
exports.GetOverwolfQuestsResponseAllOfToJSON = GetOverwolfQuestsResponseAllOfToJSON;
