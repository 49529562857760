"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildMemberMonthlyPointsToJSON = exports.GuildMemberMonthlyPointsFromJSONTyped = exports.GuildMemberMonthlyPointsFromJSON = void 0;
var runtime_1 = require("../runtime");
function GuildMemberMonthlyPointsFromJSON(json) {
    return GuildMemberMonthlyPointsFromJSONTyped(json, false);
}
exports.GuildMemberMonthlyPointsFromJSON = GuildMemberMonthlyPointsFromJSON;
function GuildMemberMonthlyPointsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'guild': json['guild'],
        'month': json['month'],
        'year': json['year'],
        'monthlyPointsEarned': json['monthlyPointsEarned'],
        'pointsEarned': !(0, runtime_1.exists)(json, 'pointsEarned') ? undefined : json['pointsEarned'],
        'user': json['user'],
    };
}
exports.GuildMemberMonthlyPointsFromJSONTyped = GuildMemberMonthlyPointsFromJSONTyped;
function GuildMemberMonthlyPointsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'guild': value.guild,
        'month': value.month,
        'year': value.year,
        'monthlyPointsEarned': value.monthlyPointsEarned,
        'pointsEarned': value.pointsEarned,
        'user': value.user,
    };
}
exports.GuildMemberMonthlyPointsToJSON = GuildMemberMonthlyPointsToJSON;
