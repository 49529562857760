"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopGuildLeaderLeaderboardToJSON = exports.TopGuildLeaderLeaderboardFromJSONTyped = exports.TopGuildLeaderLeaderboardFromJSON = void 0;
var runtime_1 = require("../runtime");
function TopGuildLeaderLeaderboardFromJSON(json) {
    return TopGuildLeaderLeaderboardFromJSONTyped(json, false);
}
exports.TopGuildLeaderLeaderboardFromJSON = TopGuildLeaderLeaderboardFromJSON;
function TopGuildLeaderLeaderboardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guildIcon': !(0, runtime_1.exists)(json, 'guildIcon') ? undefined : json['guildIcon'],
        'guildName': !(0, runtime_1.exists)(json, 'guildName') ? undefined : json['guildName'],
        'totalPoints': json['totalPoints'],
        'nickname': !(0, runtime_1.exists)(json, 'nickname') ? undefined : json['nickname'],
        'profilePicture': !(0, runtime_1.exists)(json, 'profilePicture') ? undefined : json['profilePicture'],
        'rank': json['rank'],
        'id': json['id'],
    };
}
exports.TopGuildLeaderLeaderboardFromJSONTyped = TopGuildLeaderLeaderboardFromJSONTyped;
function TopGuildLeaderLeaderboardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guildIcon': value.guildIcon,
        'guildName': value.guildName,
        'totalPoints': value.totalPoints,
        'nickname': value.nickname,
        'profilePicture': value.profilePicture,
        'rank': value.rank,
        'id': value.id,
    };
}
exports.TopGuildLeaderLeaderboardToJSON = TopGuildLeaderLeaderboardToJSON;
