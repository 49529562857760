"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTopGuildLeadersLeaderboardResponseToJSON = exports.GetTopGuildLeadersLeaderboardResponseFromJSONTyped = exports.GetTopGuildLeadersLeaderboardResponseFromJSON = void 0;
var _1 = require("./");
function GetTopGuildLeadersLeaderboardResponseFromJSON(json) {
    return GetTopGuildLeadersLeaderboardResponseFromJSONTyped(json, false);
}
exports.GetTopGuildLeadersLeaderboardResponseFromJSON = GetTopGuildLeadersLeaderboardResponseFromJSON;
function GetTopGuildLeadersLeaderboardResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leaders': (json['leaders'].map(_1.TopGuildLeaderLeaderboardFromJSON)),
    };
}
exports.GetTopGuildLeadersLeaderboardResponseFromJSONTyped = GetTopGuildLeadersLeaderboardResponseFromJSONTyped;
function GetTopGuildLeadersLeaderboardResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leaders': (value.leaders.map(_1.TopGuildLeaderLeaderboardToJSON)),
    };
}
exports.GetTopGuildLeadersLeaderboardResponseToJSON = GetTopGuildLeadersLeaderboardResponseToJSON;
