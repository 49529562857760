"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfQuestsResponseToJSON = exports.GetOverwolfQuestsResponseFromJSONTyped = exports.GetOverwolfQuestsResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetOverwolfQuestsResponseFromJSON(json) {
    return GetOverwolfQuestsResponseFromJSONTyped(json, false);
}
exports.GetOverwolfQuestsResponseFromJSON = GetOverwolfQuestsResponseFromJSON;
function GetOverwolfQuestsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, _1.PaginatedResponseFromJSONTyped)(json, ignoreDiscriminator)), { 'quests': (json['quests'].map(_1.OWQuestResponseFromJSON)), 'allEventTypes': (json['allEventTypes'].map(_1.OWEventTypeResponseFromJSON)), 'allGames': !(0, runtime_1.exists)(json, 'allGames') ? undefined : (json['allGames'].map(_1.OWGameResponseFromJSON)) });
}
exports.GetOverwolfQuestsResponseFromJSONTyped = GetOverwolfQuestsResponseFromJSONTyped;
function GetOverwolfQuestsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, _1.PaginatedResponseToJSON)(value)), { 'quests': (value.quests.map(_1.OWQuestResponseToJSON)), 'allEventTypes': (value.allEventTypes.map(_1.OWEventTypeResponseToJSON)), 'allGames': value.allGames === undefined ? undefined : (value.allGames.map(_1.OWGameResponseToJSON)) });
}
exports.GetOverwolfQuestsResponseToJSON = GetOverwolfQuestsResponseToJSON;
