import React, {ReactElement} from "react";
import {Business} from "@devour/client";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface Props {
	business: Business;
}


function BusinessCard(props: Props): ReactElement {
	return (
		<div
			className={classNames("business-card", {
				"is-active": !props.business.suspendUntil || Date.now() > props.business.suspendUntil,
				"is-inactive": props.business.suspendUntil && Date.now() < props.business.suspendUntil,
			})}
		>
			<Link to={`/businesses/${props.business.id}/details`}>
				<div className="business-card_header">
					<div className="business-card_header_image">
						<img
							src={props.business.headerImage?.url || process.env.REACT_APP_CDN_URL + "/images/placeholderitem.webp"}
							alt={props.business.name}
						/>
					</div>
					<div className="business-card_header_logo">
						<img
							src={props.business.icon?.url || process.env.REACT_APP_CDN_URL + "/images/placeholderitem.webp"}
							alt={props.business.name}
						/>
					</div>
					{(props.business.suspendUntil && Date.now() < props.business.suspendUntil) && (
						<div className="business-card_header_inactive">
							<div className="business-card_header_inactive_text">
                                Temporarily Unavailable
							</div>
						</div>
					)}
				</div>

				<h3>{props.business.name}</h3>

				{(props.business.internalName) && (
					<p className="business-card_internal">
						{props.business.internalName}
					</p>
				)}

				<p>
					{props.business.address.line1}, {props.business.address.line2}<br/>
					{props.business.address.locality} {props.business.address.administrativeArea}
				</p>
			</Link>
		</div>
	);
}

export default BusinessCard;
