import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
    Business, BusinessDays,
    BusinessesApi,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityBody,
    ErrorType,
    UtilsApi
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import { IoIosSave } from "react-icons/io";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import {isRestaurant} from "../../../utils/typeGuards";
import FrameOneSwitchInput from "../../../components/inputs/FrameOneSwitchInput";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";
import BusinessAvailabilities from "./BusinessAvailabilities";
import FrameButton from "../../../components/buttons/FrameButton";
import Spacer from "../../../components/utility/Spacer";

export interface BusinessBodyFormValues {
    timeZone: string;
    suspendUntil?: string;
    serviceAvailabilities: Array<BusinessServiceAvailability>;
}

const defaultValues: BusinessBodyFormValues = {
    suspendUntil: undefined,
    timeZone: "",
    serviceAvailabilities: [],
};

interface Props {
    business?: Business;
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    businessBodyOnChange: (updatedValues: Partial<CreateBusinessBodyFrontend>) => void;
}

function BusinessDetailsHours(props: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useNavigate();

    const [businessBody, setBusinessBody] = useState<BusinessBodyFormValues>(defaultValues);
    const [timeZones, setTimeZones] = useState<Array<string>>([]);
    const [activeEditingDay, setActiveEditingDay] = useState<BusinessDays>(undefined);

    useEffect(() => {
        void fetchTimeZones();
    }, []);

    useEffect(() => {
        if (props.business) {
            void setBusinessBodyContents();
        }
    }, [props.business]);

    async function fetchTimeZones(): Promise<void> {
        try {
            const res = await new UtilsApi().getTimeZones();
            setTimeZones(res);
        } catch (e) {
        }
    }

    async function setBusinessBodyContents(): Promise<void> {
        if (isRestaurant(props.business)) {
            setBusinessBody({
                suspendUntil: (props.business.suspendUntil && Date.now() < props.business.suspendUntil) ?
                    moment(props.business.suspendUntil).format("YYYY-MM-DDTHH:mm") :
                    undefined,
                timeZone: props.business.timeZone,
                serviceAvailabilities: props.business.serviceAvailabilities,
            });
        } else {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This business is not a restaurant.",
            }));
        }
    }

    function inputOnChange(key: keyof BusinessServiceAvailabilityBody):
        ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setBusinessBody({
                ...businessBody,
                [key]: e.target.value,
            });
        }
    }

    function operatingStatusOnChange(): void {
        const in24Hours = moment().add(1, 'day').format("YYYY-MM-DDTHH:mm");
        setBusinessBody({
            ...businessBody,
            suspendUntil: (businessBody.suspendUntil) ? undefined : in24Hours,
        });
    }

    /**
     * Adds the new availabilities to the businessBody form.
     * This is the form that is used to update data in submitUpdatedHours.
     * @param newAvailabilities
     */
    function updateBusinessBodyWithNewAvailabilities(newAvailabilities: Array<BusinessServiceAvailability>): void {
        setBusinessBody({
            ...businessBody,
            serviceAvailabilities: newAvailabilities,
        });

    }

    function updateActiveDay(day: BusinessDays | undefined): void {
        setActiveEditingDay(day);
    }

    /**
     * API call to update the DB with new business availabilities.
     */
    async function submitUpdatedHours(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new BusinessesApi(getConfig()).updateBusinessServiceAvailability({
                id: props.business.id,
                businessServiceAvailabilityBody: {
                    ...businessBody,
                    suspendUntil: (businessBody.suspendUntil) ? moment(businessBody.suspendUntil).unix() * 1000 : undefined,
                },
            });
            history(`/businesses/${props.business.id}/details`);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderBusinessDetailsPageDayGroup(day: BusinessDays, index: number): ReactElement {
        let serviceBlock: BusinessServiceAvailability = businessBody.serviceAvailabilities.find((service) =>
            service.dayOfWeek === day);
        if (!serviceBlock) {
            serviceBlock = {
                dayOfWeek: day,
                timePeriods: [],
            }
        }
        return (
            <BusinessAvailabilities
                key={`avail-${index}`}
                day={day}
                activeDay={activeEditingDay}
                updateActiveDay={updateActiveDay}
                serviceBlock={serviceBlock}
                updateBusinessBodyWithNewAvailabilities={updateBusinessBodyWithNewAvailabilities}
                businessBody={businessBody}
            />
        );
    }


    return (
        <div className="business-page-hours">
            <div className="service-availabilities-input_save-container">
                <Spacer />
                <FrameButton
                    color="purple"
                    size="narrow"
                    leftIcon={IoIosSave}
                    disabled={!!activeEditingDay}
                    className="business-page_save-contact-button"
                    onClick={async () => {
                        await submitUpdatedHours();
                    }}
                >
                    Save Changes
                </FrameButton>
            </div>

            <div className="business-page-hours_options">

                <div className="business-page-hours_section">
                    <h4>Select Timezone</h4>
                    <label>Timezone *</label>
                    <select
                        value={businessBody.timeZone}
                        onChange={inputOnChange("timeZone")}
                        required={true}
                    >
                        <option value="">Select Timezone</option>
                        {timeZones.map((zone, index) => (
                            <option key={`timezone-${index}`} value={zone}>
                                {zone}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="business-page-hours_section">
                    <h4>Operating Status</h4>
                    <p className="restaurant-update-hours-page_switch-text">
                        Use this to override the normal operating hours to temporarily close the restaurant.
                        For example, if you are closed for a holiday.
                    </p>
                    <FrameOneSwitchInput
                        <0 | 1>
                        name="schedule"
                        value={(businessBody.suspendUntil) ? 0 : 1}
                        onToggle={operatingStatusOnChange}
                        options={[
                            {
                                render: "Operating Normally",
                                value: 1,
                            },
                            {
                                render: "Temporarily Unavailable",
                                value: 0,
                            },
                        ]}
                    />

                    {(businessBody.suspendUntil) && (
                        <div
                            className="business-page-hours_suspend-until-container"
                        >
                            <h4>Suspend Until</h4>
                            <p className="restaurant-update-hours-page_switch-text">
                                Temporarily close the restaurant until the specified date and time.
                            </p>
                            <input
                                placeholder="Scheduled Time"
                                type="datetime-local"
                                value={businessBody.suspendUntil}
                                onChange={inputOnChange("suspendUntil")}
                                min={moment().format("YYYY-MM-DDTHH:mm")}
                                required={true}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="restaurant-update-hours-page_hours-container ">
                <h4>Normal Operating Hours</h4>
                <div className="service-availabilities-input_bd-flex">
                    {Object.values(BusinessDays).map(renderBusinessDetailsPageDayGroup)}
                </div>
            </div>
        </div>
    );
}

export default BusinessDetailsHours;
