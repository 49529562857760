"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAllOfToJSON = exports.UserAllOfFromJSONTyped = exports.UserAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UserAllOfFromJSON(json) {
    return UserAllOfFromJSONTyped(json, false);
}
exports.UserAllOfFromJSON = UserAllOfFromJSON;
function UserAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, _1.UserTypeFromJSON)(json['type']),
        'disabled': json['disabled'],
        'deleted': !(0, runtime_1.exists)(json, 'deleted') ? undefined : json['deleted'],
        'skipped': !(0, runtime_1.exists)(json, 'skipped') ? undefined : json['skipped'],
        'notificationMenuOrderStatus': !(0, runtime_1.exists)(json, 'notificationMenuOrderStatus') ? undefined : json['notificationMenuOrderStatus'],
        'notificationMarketing': !(0, runtime_1.exists)(json, 'notificationMarketing') ? undefined : json['notificationMarketing'],
        'email': json['email'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberFromJSON)(json['phoneNumber']),
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'ordersEmail': !(0, runtime_1.exists)(json, 'ordersEmail') ? undefined : json['ordersEmail'],
        'ordersPhoneNumber': !(0, runtime_1.exists)(json, 'ordersPhoneNumber') ? undefined : (0, _1.PhoneNumberFromJSON)(json['ordersPhoneNumber']),
        'ordersFirstName': !(0, runtime_1.exists)(json, 'ordersFirstName') ? undefined : json['ordersFirstName'],
        'ordersLastName': !(0, runtime_1.exists)(json, 'ordersLastName') ? undefined : json['ordersLastName'],
        'timeZone': !(0, runtime_1.exists)(json, 'timeZone') ? undefined : json['timeZone'],
        'addresses': !(0, runtime_1.exists)(json, 'addresses') ? undefined : (json['addresses'].map(_1.AddressBookFromJSON)),
        'favorites': !(0, runtime_1.exists)(json, 'favorites') ? undefined : json['favorites'],
        'lastSpin': !(0, runtime_1.exists)(json, 'lastSpin') ? undefined : json['lastSpin'],
        'gravatar': !(0, runtime_1.exists)(json, 'gravatar') ? undefined : json['gravatar'],
        'defaultPaymentMethodId': !(0, runtime_1.exists)(json, 'defaultPaymentMethodId') ? undefined : json['defaultPaymentMethodId'],
        'profilePicture': !(0, runtime_1.exists)(json, 'profilePicture') ? undefined : (0, _1.AssetFromJSON)(json['profilePicture']),
        'level': !(0, runtime_1.exists)(json, 'level') ? undefined : json['level'],
        'experiencePoints': !(0, runtime_1.exists)(json, 'experiencePoints') ? undefined : json['experiencePoints'],
        'nickname': !(0, runtime_1.exists)(json, 'nickname') ? undefined : json['nickname'],
        'community': !(0, runtime_1.exists)(json, 'community') ? undefined : json['community'],
        'leftCommunityAt': !(0, runtime_1.exists)(json, 'leftCommunityAt') ? undefined : json['leftCommunityAt'],
        'joinedCommunityAt': !(0, runtime_1.exists)(json, 'joinedCommunityAt') ? undefined : json['joinedCommunityAt'],
        'previousCommunity': !(0, runtime_1.exists)(json, 'previousCommunity') ? undefined : json['previousCommunity'],
        'pointsEarnedForCommunity': !(0, runtime_1.exists)(json, 'pointsEarnedForCommunity') ? undefined : json['pointsEarnedForCommunity'],
        'monthlyPointsEarnedForCommunity': !(0, runtime_1.exists)(json, 'monthlyPointsEarnedForCommunity') ? undefined : json['monthlyPointsEarnedForCommunity'],
        'rankWithinCommunity': !(0, runtime_1.exists)(json, 'rankWithinCommunity') ? undefined : json['rankWithinCommunity'],
        'isEjectedFromCommunity': !(0, runtime_1.exists)(json, 'isEjectedFromCommunity') ? undefined : json['isEjectedFromCommunity'],
        'nftProfilePicture': !(0, runtime_1.exists)(json, 'nftProfilePicture') ? undefined : json['nftProfilePicture'],
        'discord': !(0, runtime_1.exists)(json, 'discord') ? undefined : (0, _1.DiscordInfoFromJSON)(json['discord']),
    };
}
exports.UserAllOfFromJSONTyped = UserAllOfFromJSONTyped;
function UserAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, _1.UserTypeToJSON)(value.type),
        'disabled': value.disabled,
        'deleted': value.deleted,
        'skipped': value.skipped,
        'notificationMenuOrderStatus': value.notificationMenuOrderStatus,
        'notificationMarketing': value.notificationMarketing,
        'email': value.email,
        'phoneNumber': (0, _1.PhoneNumberToJSON)(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'ordersEmail': value.ordersEmail,
        'ordersPhoneNumber': (0, _1.PhoneNumberToJSON)(value.ordersPhoneNumber),
        'ordersFirstName': value.ordersFirstName,
        'ordersLastName': value.ordersLastName,
        'timeZone': value.timeZone,
        'addresses': value.addresses === undefined ? undefined : (value.addresses.map(_1.AddressBookToJSON)),
        'favorites': value.favorites,
        'lastSpin': value.lastSpin,
        'gravatar': value.gravatar,
        'defaultPaymentMethodId': value.defaultPaymentMethodId,
        'profilePicture': (0, _1.AssetToJSON)(value.profilePicture),
        'level': value.level,
        'experiencePoints': value.experiencePoints,
        'nickname': value.nickname,
        'community': value.community,
        'leftCommunityAt': value.leftCommunityAt,
        'joinedCommunityAt': value.joinedCommunityAt,
        'previousCommunity': value.previousCommunity,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'monthlyPointsEarnedForCommunity': value.monthlyPointsEarnedForCommunity,
        'rankWithinCommunity': value.rankWithinCommunity,
        'isEjectedFromCommunity': value.isEjectedFromCommunity,
        'nftProfilePicture': value.nftProfilePicture,
        'discord': (0, _1.DiscordInfoToJSON)(value.discord),
    };
}
exports.UserAllOfToJSON = UserAllOfToJSON;
