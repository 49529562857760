"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfQuestsSortToJSON = exports.GetOverwolfQuestsSortFromJSONTyped = exports.GetOverwolfQuestsSortFromJSON = exports.GetOverwolfQuestsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetOverwolfQuestsSort;
(function (GetOverwolfQuestsSort) {
    GetOverwolfQuestsSort["CREATEDAT"] = "CREATED_AT";
    GetOverwolfQuestsSort["NAME"] = "NAME";
    GetOverwolfQuestsSort["GAME"] = "GAME";
})(GetOverwolfQuestsSort = exports.GetOverwolfQuestsSort || (exports.GetOverwolfQuestsSort = {}));
function GetOverwolfQuestsSortFromJSON(json) {
    return GetOverwolfQuestsSortFromJSONTyped(json, false);
}
exports.GetOverwolfQuestsSortFromJSON = GetOverwolfQuestsSortFromJSON;
function GetOverwolfQuestsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetOverwolfQuestsSortFromJSONTyped = GetOverwolfQuestsSortFromJSONTyped;
function GetOverwolfQuestsSortToJSON(value) {
    return value;
}
exports.GetOverwolfQuestsSortToJSON = GetOverwolfQuestsSortToJSON;
