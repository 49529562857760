"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddGuildBodyToJSON = exports.AddGuildBodyFromJSONTyped = exports.AddGuildBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AddGuildBodyFromJSON(json) {
    return AddGuildBodyFromJSONTyped(json, false);
}
exports.AddGuildBodyFromJSON = AddGuildBodyFromJSON;
function AddGuildBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'groupingId': json['groupingId'],
        'games': json['games'],
        'autoMintChain': !(0, runtime_1.exists)(json, 'autoMintChain') ? undefined : (0, _1.EVMChainFromJSON)(json['autoMintChain']),
        'autoMintContract': !(0, runtime_1.exists)(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'hero': !(0, runtime_1.exists)(json, 'hero') ? undefined : json['hero'],
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
    };
}
exports.AddGuildBodyFromJSONTyped = AddGuildBodyFromJSONTyped;
function AddGuildBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'groupingId': value.groupingId,
        'games': value.games,
        'autoMintChain': (0, _1.EVMChainToJSON)(value.autoMintChain),
        'autoMintContract': value.autoMintContract,
        'isActive': value.isActive,
        'hero': value.hero,
        'icon': value.icon,
    };
}
exports.AddGuildBodyToJSON = AddGuildBodyToJSON;
