"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LootboxRewardAllOfToJSON = exports.LootboxRewardAllOfFromJSONTyped = exports.LootboxRewardAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function LootboxRewardAllOfFromJSON(json) {
    return LootboxRewardAllOfFromJSONTyped(json, false);
}
exports.LootboxRewardAllOfFromJSON = LootboxRewardAllOfFromJSON;
function LootboxRewardAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'levelCompleted': !(0, runtime_1.exists)(json, 'levelCompleted') ? undefined : json['levelCompleted'],
    };
}
exports.LootboxRewardAllOfFromJSONTyped = LootboxRewardAllOfFromJSONTyped;
function LootboxRewardAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'levelCompleted': value.levelCompleted,
    };
}
exports.LootboxRewardAllOfToJSON = LootboxRewardAllOfToJSON;
